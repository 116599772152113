import React from 'react'
import styled from '@emotion/styled'
import { H1 } from '../components/shared/type'
import Container from '../components/shared/container'
import { Hr as BaseHr } from '../components/shared/rules'
import { mediaQueries } from '../theme'
import PageMetadata from '../components/page-metadata'
import Layout from '../components/layout'
import { SignUpCta, LoginCta } from '../components/not-published/cta'

const InnerContainer = styled(Container)`
  ${mediaQueries.lg} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${p => p.theme.space[14]} 0;

  ${mediaQueries.lg} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 ${p => p.theme.space[26]};
  }
`

const Title = styled(H1)`
  margin: 0;
  text-align: center;
  line-height: 2.75rem;
`

const Hr = styled(BaseHr)`
  margin: ${p => p.theme.space[12]} 0;
  ${mediaQueries.lg} {
    margin: ${p => p.theme.space[15]} 0;
    max-width: 45rem;
  }
`

const PageLayout = props => (
  <Layout withNav={false} withFooter={false} {...props} />
)

const NotPublished = ({ data, location }) => (
  <PageLayout location={location}>
    <PageMetadata
      title="This Online Form is Not Active - You Can't Preview It"
      description="You can view this online form because it's either deactivated by the owner or the account it belongs to is blocked. "
      keywords=""
      robots="noindex"
    />
    <InnerContainer>
      <Title>This form isn’t published</Title>
      <Hr />
      <Actions>
        <LoginCta />
        <SignUpCta />
      </Actions>
    </InnerContainer>
  </PageLayout>
)

export default NotPublished
