import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { getLoginUrl } from '../../utils/auth'
import Link from '../shared/link'
import BaseButton from '../shared/button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.p`
  margin: 0;
  margin-bottom: ${p => p.theme.space[4]};
  font-size: ${p => p.theme.fontSizes[4]};
  line-height: 1.4;
  font-weight: ${p => p.theme.fontWeights.heading};
`

const P = styled.p`
  margin: 0;

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[3]};
    line-height: 1.4;
  }
`

const Button = styled(withAttrs(BaseButton, { as: Link }))`
  min-width: ${p => p.theme.space[26]};
  margin-top: ${p => p.theme.space[6]};
`

const LOGIN_URL = getLoginUrl()

export const LoginCta = props => (
  <Container {...props}>
    <Title>Your form?</Title>
    <P>Log in to make changes.</P>
    <Button to={LOGIN_URL}>Log in</Button>
  </Container>
)

export const SignUpCta = props => (
  <Container {...props}>
    <Title>Not your form?</Title>
    <P>Create your own free form!</P>
    <Button to="/">Explore free forms</Button>
  </Container>
)
